angular.module("aerosApp")
    .controller("InspectionResultsCtrl", InspectionResultsCtrl);

InspectionResultsCtrl.$inject = ["$scope", "aerosApi", "$state", "$stateParams",
    "$uibModal", "configService", "wavelengths", "$timeout", "$rootScope", "projectSummary", "TestResultsService",
    "project", "routesConstant"];

function InspectionResultsCtrl($scope, aerosApi, $state, $stateParams, $uibModal, configService, wavelengths, $timeout,
                               $rootScope, projectSummary, TestResultsService, project, routesConstant) {
    var CUSTOMER = routesConstant.CUSTOMER;
    $scope.tool = $stateParams.testType;
    $scope.summary = projectSummary;
    $scope.fiberGroups = projectSummary.fiberGroups.filter(toolTypeCriteria);

    if (!$scope.project || !$scope.project.fiberGroups) {
        $scope.project = project;
    }

    // inherit if exists on parent (nested fibergroup view)
    if (!$scope.resultType) {
        $scope.resultType = {selected: $stateParams.resultType || "all"};
    }

    loadResults($stateParams.id);

    $scope.testType = $stateParams.testType;
    $scope.formData = {
        queryString: $stateParams.qry
    };
    if ($stateParams.qry.substring(0, 3) === "id=") {
        $scope.formData.queryString = "";
        $scope.fibergroupId = $stateParams.qry.substring(3);
    }

    $scope.wavelengths = wavelengths;

    //Note: There is currently a copy of this and addUntestedFiberResults in the ReportController.
    //These should be combined at some point. However, until then make changes there too,
    function loadResults(projectId) {
        if (!$scope.allow['viewProject']) return $scope.reject();
        configService.getPromise().then(function (config) {
            aerosApi.loadProjectResults(projectId, $scope.organization.id).success(function (data) {
                $scope.results = data;
                addUntestedFiberResults($scope.results);
                initBidirectional();
                $scope.refactor = TestResultsService.refactorData(data);
                $scope.inspections = TestResultsService.getProjectInspectionStandards($scope.$resolve.project, data) ;
            });
        });
    }

    $scope.iconMap = {
        "Failed": "times",
        "PassedOrComplete": "check",
        "Incomplete": "question"
    };

    $scope.showFiberGroupInfo = function (fg) {
        var fiberGroup = $scope.fiberGroupById[fg.id];
        $uibModal.open({
            size: "md",
		      templateUrl: 'fiberGroupInfoModalInspection.html',
		      controller: ["$scope", "fiberGroup", function($scope, fiberGroup){
		    	  $scope.fiberGroup = fiberGroup;
		      }],
		      resolve: {
		        fiberGroup: function () {
		          return fiberGroup;
		        }
		      }
		    });
	};

    $scope.isToolApplied = function () {
        if ($stateParams.resultType !== 'all') {

            if (typeof $scope.summary !== 'undefined') {
                var fb = $scope.fiberGroups.find(function (obj) {
                    return obj.uuid === $scope.fibergroupId;
                });
            }

            return toolTypeCriteria(fb);
        } else {
            return typeof $scope.fiberGroups !== 'undefined' && $scope.fiberGroups;
        }
    };

    function toolTypeCriteria(fg) {
        return fg && fg.summaryResults && 'Inspection' in fg.summaryResults;
    }

    $scope.isResultsExists = function () {
        if ($stateParams.resultType !== 'all') {

            var isInspectionResultsFound = false;

            if (typeof $scope.results !== 'undefined') {
                var fb = $scope.results.find(function (obj) {
                    return obj.uuid === $scope.fibergroupId;
                });

                fb && fb.testResults && fb.testResults.forEach(function (result) {
                    if (!$.isEmptyObject(result.inspectionMeasurements)) {
                        isInspectionResultsFound = true;
                    }
                })
            }

            return isInspectionResultsFound;
        } else {
            return typeof $scope.fiberGroups !== 'undefined' && $scope.fiberGroups;
        }

    };

    $scope.bidriectionalExists = false;

    function addUntestedFiberResults(results) {
        _.forEach(results, function (fiberGroup) {
            for (var i = fiberGroup.startFiber; i <= fiberGroup.fiberCount; i++) {
                if (_.find(fiberGroup.testResults, {'fiberId': i.toString()}) == null) {
                    fiberGroup.testResults.push({fiberId: i, failedTests: [], status: 'Incomplete'});
                }
            }
        });
    }

    $scope.fiberGroupById = {};

    function initBidirectional() {
        for (var i = 0; i < $scope.project.fiberGroups.length; i++) {
            var fiberGroup = $scope.project.fiberGroups[i];
            $scope.fiberGroupById[fiberGroup.id] = fiberGroup;
            if ($scope.bidriectionalExists === false) {
                //check if the page has been filtered down to one fiber group's results
                if ($stateParams.qry && fiberGroup.id !== $scope.fibergroupId) {
                    //this fiber group is not the one that has been filtered to
                    //jump out of the loop and go to the next fiber group
                    continue;
                }
                for (var j = 0; j < fiberGroup.testSetups.length; j++) {
                    var testSetup = fiberGroup.testSetups[j];
                    if (testSetup.direction === "Bidirectional") {
                        $scope.bidriectionalExists = true;
                        break;
                    }
                }
            }
        }
    }

    $scope.isIncomplete = function (fiberGroup, result) {
        return fiberGroup.status == 'Incomplete';
    };

    function hasAnyResultByType(results, type) {
        return _.some(results, function (result) {
            return result.status === type;
        });
    }

    $scope.getFiberGroupStatus = function (fiberGroup) {
        if (hasAnyResultByType(fiberGroup.testResults, "Failed")) {
            return "Failed";
        } else if (hasAnyResultByType(fiberGroup.testResults, "Incomplete")) {
            return "Incomplete";
        } else {
            return "PassedOrComplete";
        }
    };

    $scope.showResult = function (fiberGroup, result, resultType) {
        if (resultType === 'all') {
            return true;
        }

        if (resultType === 'complete') {
            return (result.status == 'PassedOrComplete');
        }

        if (resultType === 'failed-any') {
            return result.status == 'Failed';
        }

        if (resultType === 'incomplete') {
            return result.status == 'Incomplete';
        }

        if (resultType === 'failed-length') {
            if (typeof result.failedLength === "boolean") return result.failedLength;
            return (result.lengthMeasurement != null) && result.lengthMeasurement.status == 'Failed';
        }

        if (resultType === 'failed-loss') {
            return _.some(result.lossMeasurements, function (lossMeasurement) {
                return hasFailure(lossMeasurement.MainToRemote) || hasFailure(lossMeasurement.RemoteToMain);
            });
        }
        if (resultType === 'failed-loss-only') {
            return result.failedLoss;
        }
        if (resultType === 'failed-orl-only') {
            return result.failedORLLoss;
        }

        if (resultType === 'failed-inspection') {
            if (typeof result.failedInspection === "boolean") return result.failedInspection;
            var mainEfi = result.inspectionMeasurements.Main;
            var remoteEfi = result.inspectionMeasurements.Remote;
            if (mainEfi) {
                if (mainEfi.status === 'Failed') {
                    return true;
                }
            }
            if (remoteEfi) {
                if (remoteEfi.status === 'Failed') {
                    return true;
                }
            }
            return false;
        }

        return false;
    };

    function hasFailure(directedLoss) {
        return directedLoss != null && directedLoss.failedLimits.length > 0;
    }

    $scope.hasORLResults = function (result) {
        return !angular.equals({}, result.orlLossMeasurements);
    };

    $scope.filterFiberGroups = function (fiberGroup) {
        if ($scope.fibergroupId) {
            return fiberGroup.uuid === $scope.fibergroupId;
        }

        if ($scope.formData.queryString.length == 0) {
            return true;
        }

        return fiberGroup.name.indexOf($scope.formData.queryString) != -1;
    };

    $scope.showTestType = function (fiberGroup, testType) {
        return _.some(fiberGroup.testResults, function (result) {
            return !_.isEmpty(result[testType.toLowerCase() + "Measurements"]);
        });
    };

    $scope.showInspectionMismatch = function (test) {
        return test.standardMismatch ;
    };

    $scope.hasAnyOltsResults = function (flatResults) {
        return _.some(flatResults.fibers, function (fiber) {
            return !!fiber.lengthStatus && !_.isEmpty(fiber.wavelengths);
        });
    };

    $scope.showFiberGroup = function (queryString) {
        $state.go(CUSTOMER.PROJECTS_PROJECT_FIBERGROUPS.stateName, {id: $scope.project.id, qry: queryString});
    };

    var toggleOptions = ["Show Bidirectional Loss", "Show Average Loss"];
    $scope.bidirectionalToggleLabel = toggleOptions[0];
    $scope.toggleBidirectionalView = function () {
        $scope.bidirectionalToggleLabel = toggleOptions[1 - toggleOptions.indexOf($scope.bidirectionalToggleLabel)];
    };
    $scope.deselectProjects();

    // TODO: inject as constant (from API?) - here and popup
    $scope.pairModes = {
        "Before/After": {
            "before": "Before",
            "after": "After"
        },
        "Input/Output": {
            "before": "Input",
            "after": "Output"
        },
        "Jumper/Bulkhead": {
            "before": "Jumper",
            "after": "Bulkhead"
        },
        "As built/As found": {
            "before": "As built",
            "after": "As found"
        }
    };


    $scope.togglePairDemo = function () {
        if ($scope.demoAction === "Show") {
            $scope.cacheResults = $scope.results;
            $scope.results = demoFGs;
            $scope.demoAction = "Hide";
        } else {
            $scope.results = $scope.cacheResults;
            $scope.demoAction = "Show";
        }
    };
}
